@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes revealCharCell {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}
