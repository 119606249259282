html.dark {
  background-color: rgb(15, 23, 42);
}

ul {
  position: relative;
  display: flex;
}

li {
  border-radius: 10px;
  margin-bottom: 10px;
  width: 100%;
  margin: 2px;
  padding: 15px 18px;
  position: relative;
  background: white;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  cursor: grab;
}
